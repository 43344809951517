import { useRegionCode } from "modules/i18n/hooks/useRegionCode"
import Cookies from "js-cookie"
import { useEffect } from "react"
import getConfig from "next/config"

const COOKIE_EXPIRATION_DAYS = 30
const COOKIE_NAME = "tc_region"
export const COOKIE_DOMAIN = () => getConfig().publicRuntimeConfig.TC_COOKIE_DOMAIN

/**
 * Hook to store the current region code in the `tc_region` cookie.
 */
export const useRememberRegion = () => {
  const regionCode = useRegionCode()

  useEffect(() => {
    Cookies.set(COOKIE_NAME, regionCode, {
      domain: COOKIE_DOMAIN(),
      expires: COOKIE_EXPIRATION_DAYS,
      sameSite: "lax",
    })
  }, [regionCode])
}
