import type { GetServerSideProps } from "next"
import { usePageProps } from "common/hooks/data/usePageProps"
import { AppProps, getAppServerSideProps } from "common/lib/getAppServerSideProps"
import { BlocksRenderer } from "modules/editor/blocks/BlocksRenderer"
import { type NextPageWithLayout } from "common/types/page"
import { Layout } from "./layout"
import { gql } from "common/lib/gql"
import { PageTypeEnum, ViewPageQuery } from "common/types/graphql"
import { fetchGraphqlQuery } from "common/lib/fetchGraphqlQuery"
import getConfig from "next/config"
import { MetaAttributes } from "common/components/MetaAttributes"
import { DEFAULT_HOST, HOME_PAGE_SLUG, pageUrl } from "common/lib/router"
import { tracer } from "common/lib/tracer"
import { useRememberRegion } from "common/hooks/data/useRememberRegion"
import Head from "next/head"

const QUERY = gql`
  query viewPage($regionCode: String!, $pageSlug: String!) {
    currentPage(regionCode: $regionCode, slug: $pageSlug) {
      cacheKey
      pageType
      doc {
        meta
        blocks {
          type
          props
          ... on CallToActionBlock {
            href
            title
            text
            style
          }
          ... on GridBlock {
            blocks {
              type
              ... on ContentSummaryBlock {
                type
                id
                url
                tagStyle
                tagLabel
                title
                customTitle
                byline
                contentType
                hideByline
                image {
                  src
                  alt
                  placeholderColor
                  paletteData {
                    averageLuminance
                  }
                  crops {
                    type
                    width
                    height
                    x
                    y
                  }
                }
              }
              ... on CustomUrlBlock {
                type
                props
                title
                href
                byline
                tagLabel
                tagStyle
                imageAlt
                image {
                  id
                  src
                  alt
                  placeholderColor
                  paletteData {
                    averageLuminance
                  }
                  crops {
                    type
                    width
                    height
                    x
                    y
                  }
                }
              }
            }
            slotSizes
            slotTypes
          }
          ... on PartnersBlock {
            type
            explainer
            partnerLinkDescription
            partners {
              name
              logoUrl
              path
              type
            }
          }
          ... on PodcastsBlock {
            linkToAllPodcasts
            type
            blocks {
              type
              ... on PodcastSummaryBlock {
                title
                href
                description
                imageAlt
                badge
                averageLengthMinutes
                image {
                  id
                  src
                  alt
                  placeholderColor
                  paletteData {
                    averageLuminance
                  }
                  crops {
                    type
                    width
                    height
                    x
                    y
                  }
                }
              }
            }
          }
          ... on MostReadBlock {
            type
            contentSummaries {
              id
              title
              byline
              url
              image {
                src
                placeholderColor
                paletteData {
                  averageLuminance
                }
                crops {
                  type
                  width
                  height
                  x
                  y
                }
              }
            }
          }
          ... on EventsBlock {
            type
            events {
              id
              name
              byline
              imageSrc
              trackingUrl
            }
          }
          ... on JobsBoardBlock {
            type
            jobs {
              expirationDate
              location
              logoUrl
              title
              url
            }
          }
        }
      }
    }
  }
`

export interface ViewPageProps extends AppProps {
  blocks: any[] // Block[]
  meta: {
    title?: string
    description?: string
  }
  pageType?: PageTypeEnum
  canonicalUrl: string
}

export const getServerSideProps: GetServerSideProps<ViewPageProps> = async ({
  query,
  res,
  req,
}) => {
  const appProps = await getAppServerSideProps({ query, res, req })
  const { serverRuntimeConfig } = getConfig()
  const { regionCode, pageSlug } = appProps

  let headers: { Authorization: string; User?: string } = {
    Authorization: `Bearer ${serverRuntimeConfig.TC_FRONTEND_TOKEN}`,
  }

  const data = await fetchGraphqlQuery<ViewPageQuery>(
    QUERY,
    headers,
    {
      regionCode,
      pageSlug,
    },
    tracer,
  )

  const pageType = data.currentPage?.pageType
  const blocks = data.currentPage?.doc.blocks
  const meta = data.currentPage?.doc.meta

  if (!blocks) {
    return { notFound: true }
  }

  res.setHeader("Cache-Control", "no-store")
  res.setHeader("Surrogate-Control", "max-age=14400") // Pages expires in 4 hours
  res.setHeader("Surrogate-Key", data.currentPage?.cacheKey || "")

  return {
    props: {
      ...appProps,
      pageType,
      blocks,
      meta,
      editUrl: pageUrl.edit({ regionCode, pageSlug }),
      canonicalUrl: pageUrl.view({
        regionCode,
        pageSlug: pageSlug === HOME_PAGE_SLUG ? "" : pageSlug,
        host: DEFAULT_HOST,
        prefix: false,
      }),
    },
  }
}

const ViewPage: NextPageWithLayout<ViewPageProps> = () => {
  const { blocks, meta, canonicalUrl } = usePageProps<ViewPageProps>()
  useRememberRegion()

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <MetaAttributes
        description={meta?.description}
        title={meta?.title}
        ogDescription={meta?.description}
        ogTitle={meta?.title}
      />

      <BlocksRenderer blocks={blocks} />
    </>
  )
}

ViewPage.getLayout = (page) => <Layout>{page}</Layout>

export default ViewPage
